import moment from "moment";

import server from "@/helpers/apiServer";

const courtSearch = async (val, callback) => {
  if (val.length > 0) {
    // const filter = { countryId };
    const filter = {};
    const response = await server.find("courts", {
      text: val,
      page: 1,
      perPage: 50,
      filter,
    });

    callback(response.data.map(({ id, alias }) => ({ id, alias })));
  }
};

let generalAttributes = [];
let denunciaAttributes = [];
let juicioAttributes = [];

generalAttributes.push({ key: "createdAt", type: "datetime" });
generalAttributes.push({ key: "datetime", type: "datetime" });
generalAttributes.push({
  key: "caseStatus",
  enumType: "caseStatuses",
  type: "enum",
});
generalAttributes.push({
  key: "customer",
  collection: "customers",
  type: "alias",
});
generalAttributes.push({
  key: "establishment",
  collection: "establishments",
  type: "alias",
});
generalAttributes.push({ key: "lawyer", collection: "lawyers", type: "alias" });
generalAttributes.push({
  key: "penalType",
  enumType: "penalTypes",

  type: "enumArray",
  joinText: " + ",
});
//-----------------------------
denunciaAttributes.push({
  key: "complaintDatetime",

  type: "datetime",
});
denunciaAttributes.push({ key: "attestation", type: "text" });
denunciaAttributes.push({
  key: "complaintLawyer",
  collection: "lawyers",

  type: "alias",
});
denunciaAttributes.push({
  key: "complaintPlace",
  enumType: "complaintPlaces",

  type: "enum",
});
//-----------------------------
juicioAttributes.push({
  key: "trialDatetime",

  type: "datetime",
});
juicioAttributes.push({
  key: "trialLawyer",
  collection: "lawyers",

  type: "alias",
});
juicioAttributes.push({
  key: "courtInstruction",
  collection: "courts",

  type: "alias",
  search: courtSearch,
});
juicioAttributes.push({
  key: "courtPenal",
  collection: "courts",

  type: "alias",
  search: courtSearch,
});
juicioAttributes.push({
  key: "autosMisdemeanor",

  type: "text",
});
juicioAttributes.push({
  key: "autosPreviousProceedings",

  type: "text",
});
juicioAttributes.push({
  key: "autosAbbreviatedProcedure",

  type: "text",
});
juicioAttributes.push({
  key: "trialStatus",
  enumType: "trialStatuses",
  type: "enum",
});
juicioAttributes.push({
  key: "restrainingOrder",

  type: "boolean",
});
juicioAttributes.push({
  key: "enforcementNumber",

  type: "text",
});
juicioAttributes.push({
  key: "enforcementCourt",
  collection: "courts",

  type: "alias",
  search: courtSearch,
});
juicioAttributes.push({
  key: "enforcementRestrainingOrderStartDate",

  type: "date",
});
juicioAttributes.push({
  key: "enforcementRestrainingOrderEndDate",

  type: "date",
});

export default {
  attributes(role) {
    let filterAttributes = (list) => {
      if (["supercustomer", "customer"].includes(role)) {
        //Super customer y customer tiene algunos campos filtrados
        let notAvailableFields = [
          "customer",
          "lawyer",
          "complaintLawyer",
          "trialLawyer",
        ];
        return list.filter((e) => notAvailableFields.indexOf(e.key) < 0);
      } else if (role === "lawyer") {
        //Lawyer tiene algunos campos filtrados
        let notAvailableFields = ["lawyer", "complaintLawyer", "trialLawyer"];
        return list.filter((e) => notAvailableFields.indexOf(e.key) < 0);
      } else {
        //Manager y admin ven todo
        return list;
      }
    };
    return [
      {
        key: "general",
        attributes: filterAttributes(generalAttributes),
      },
      {
        key: "complaint",
        attributes: filterAttributes(denunciaAttributes),
      },
      {
        key: "trial",
        attributes: filterAttributes(juicioAttributes),
      },
    ];
  },
  filterResult(field, caseValue, value) {
    if (field.nullAsFalse) {
      caseValue =
        caseValue === null || caseValue === undefined ? false : caseValue;
    }
    let filterResult = caseValue !== null && caseValue !== undefined;
    if (filterResult) {
      if (field.type === "text") {
        filterResult =
          caseValue.toLowerCase().indexOf(value.toLowerCase()) >= 0;
      } else if (field.type === "boolean") {
        filterResult = value === caseValue;
      } else if (field.type === "datetime" || field.type === "date") {
        let beginDate = moment(value.begin.toDate())
          .utcOffset(60)
          .startOf("day");
        let endDate = moment(value.end.toDate()).utcOffset(60).endOf("day");
        filterResult = moment(caseValue.toDate())
          .utcOffset(60)
          .isBetween(beginDate, endDate);
      } else if (field.type === "decimal") {
        let beginResult =
          value.begin !== null ? caseValue >= value.begin : true;
        let endResult = value.end !== null ? caseValue <= value.end : true;
        filterResult = beginResult && endResult;
      } else if (field.type === "alias" || field.type === "aliasArray") {
        filterResult = value.indexOf(caseValue.id) >= 0;
      } else if (field.type === "enum" || field.type === "enumArray") {
        if (Array.isArray(caseValue)) {
          filterResult = value.every((x) =>
            caseValue.map((y) => y.id).includes(x)
          );
        } else {
          filterResult = value.indexOf(caseValue.id) >= 0;
        }
      } else if (field.type === "select") {
        filterResult = value.some((v) => v === caseValue.id);
      } else {
        console.log("APLICAR FILTRO TIPO : ", field.key, field, value);
      }
    }

    return filterResult;
  },
  filterSimpleCriteria(role, caseElement, appliedFilters) {
    let result = true;
    let allAttributes = [].concat(
      ...this.attributes(role).map((a) => a.attributes)
    );
    let keys = Object.keys(appliedFilters);
    for (let i = 0; i < keys.length && result; i++) {
      let key = keys[i];
      let value = appliedFilters[key];
      let field = allAttributes.find((a) => a.key === key);

      let caseValue = caseElement[field.key];

      let filterResult = this.filterResult(field, caseValue, value);

      result = result && filterResult;
    }

    return result;
  },
};
