<template>
  <vs-input
    v-if="field.type === 'text'"
    class="w-full"
    v-model="basicValue"
    :name="field.key"
    :type="field.inputType || 'text'"
  />
  <v-select
    v-else-if="field.type === 'boolean'"
    :options="[
      { label: $t('boolean.yes'), value: true },
      { label: $t('boolean.no'), value: false },
    ]"
    :reduce="(option) => option.value"
    v-model="basicValue"
    class="mb-4 md:mb-0"
    :name="field.key"
    v-validate="'required'"
  />
  <div
    class="vx-row"
    v-else-if="field.type === 'datetime' || field.type === 'date'"
  >
    <div class="vx-col sm:w-1/2">
      <datetime
        v-model="dateBegin"
        type="date"
        input-class="flatpickr-input"
        format="dd-LL-yyyy"
        :phrases="{
          ok: $t('buttons.ok'),
          cancel: $t('buttons.cancel'),
        }"
        :minute-step="5"
        :name="`${field.key}-begin`"
        :placeholder="$t('advancedSearch.placeholders.dateBegin')"
        value-zone="UTC"
        zone="UTC"
      />
      <a class="flatpickr-clear" @click="dateBegin = null" v-if="!!dateBegin">
        <feather-icon
          icon="XIcon"
          svgClasses="h-4 w-4"
          class="flatpickr-clear-button ml-1"
        />
      </a>
    </div>
    <div class="vx-col sm:w-1/2">
      <datetime
        v-model="dateEnd"
        type="date"
        input-class="flatpickr-input"
        format="dd-LL-yyyy"
        :phrases="{
          ok: $t('buttons.ok'),
          cancel: $t('buttons.cancel'),
        }"
        :minute-step="5"
        :name="`${field.key}-end`"
        :placeholder="$t('advancedSearch.placeholders.dateEnd')"
        value-zone="UTC"
        zone="UTC"
        :disabled="!dateBegin"
      />
    </div>
  </div>
  <div class="vx-row" v-else-if="field.type === 'decimal'">
    <div class="vx-col sm:w-1/2">
      <vs-input
        class="w-full"
        v-model.number="decimalBegin"
        :name="`${field.key}-begin`"
        :lang="$i18n.locale"
        :placeholder="$t('advancedSearch.placeholders.decimalBegin')"
      />
    </div>
    <div class="vx-col sm:w-1/2">
      <vs-input
        class="w-full"
        v-model.number="decimalEnd"
        :name="`${field.key}-end`"
        :lang="$i18n.locale"
        :placeholder="$t('advancedSearch.placeholders.decimalEnd')"
      />
    </div>
  </div>
  <v-select
    v-else-if="
      (field.type === 'alias' ||
        field.type === 'aliasArray' ||
        field.type === 'select') &&
      !!searchableOptions &&
      !!field.search
    "
    label="alias"
    :reduce="(option) => option.id"
    :options="searchableOptions"
    :getOptionLabel="(o) => o.alias"
    :clearable="false"
    multiple
    v-model="basicValue"
    class="mb-4 md:mb-0"
    :name="field.key"
    @search="(v) => field.search(v, (o) => (searchableOptions = o))"
  />
  <v-select
    v-else-if="
      (field.type === 'alias' ||
        field.type === 'aliasArray' ||
        field.type === 'select') &&
      !!options
    "
    label="alias"
    :reduce="(option) => option.id"
    :options="options"
    :getOptionLabel="(o) => o.alias"
    :clearable="false"
    multiple
    v-model="basicValue"
    class="mb-4 md:mb-0"
    :name="field.key"
  />
  <v-select
    v-else-if="
      (field.type === 'enum' || field.type === 'enumArray') && !!options
    "
    label="alias"
    :reduce="(option) => option.id"
    :options="
      options.map((x) => ({
        id: x.id,
        key: x.key,
        alias: $t(`enums.${field.enumType}.${x.key}`),
      }))
    "
    :getOptionLabel="(o) => $t(`enums.${field.enumType}.${o.key}`)"
    :clearable="false"
    multiple
    v-model="basicValue"
    class="mb-4 md:mb-0"
    :name="field.key"
  />
  <div class="field-loading" v-else>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/app";
import vSelect from "vue-select";

// import searchHelpers from "@/helpers/advancedSearchHelper";

import "vue-datetime/dist/vue-datetime.css";
import { Datetime } from "vue-datetime";
import { Settings } from "luxon";

export default {
  name: "SearchSimpleCriteria",
  components: {
    datetime: Datetime,
    vSelect,
  },
  props: {
    field: Object,
    model: Object,
    collections: Object,
  },
  created() {
    Settings.defaultLocale = this.$i18n.locale;
  },
  data() {
    if (this.field.type === "datetime" || this.field.type === "date") {
      let value = this.model ? this.model[this.field.key] : null;
      if (value) {
        this.dateBegin = value.begin ? value.begin.toDate().toISOString() : "";
        this.dateEnd = value.end
          ? value.end.toDate().toISOString()
          : this.dateBegin;
      } else {
        this.dateBegin = "";
        this.dateEnd = "";
      }
    } else if (this.field.type === "decimal") {
      let value = this.model ? this.model[this.field.key] : null;
      if (value) {
        this.decimalBegin = value.begin || null;
        this.decimalEnd = value.end || null;
      } else {
        this.decimalBegin = null;
        this.decimalEnd = null;
      }
    } else {
      this.basicValue = this.model ? this.model[this.field.key] : null;
    }
    return {
      basicValue: this.basicValue,
      dateBegin: this.dateBegin,
      dateEnd: this.dateEnd,
      decimalBegin: this.decimalBegin,
      decimalEnd: this.decimalEnd,
      searchableOptions: [],
    };
  },
  watch: {
    dateBegin() {
      if (this.dateBegin) {
        let value = this.model ? this.model[this.field.key] : null;
        if (!this.dateEnd) {
          this.dateEnd =
            value && value.end
              ? value.end.toDate().toISOString()
              : this.dateBegin;
        } else {
          let begin = firebase.firestore.Timestamp.fromDate(
            new Date(this.dateBegin)
          );
          let end = firebase.firestore.Timestamp.fromDate(
            new Date(this.dateEnd)
          );
          this.$emit("change", { begin, end });
        }
      } else {
        this.dateEnd = null;
      }
    },
    dateEnd() {
      if (this.dateEnd) {
        let begin = firebase.firestore.Timestamp.fromDate(
          new Date(this.dateBegin)
        );
        let end = firebase.firestore.Timestamp.fromDate(new Date(this.dateEnd));
        this.$emit("change", { begin, end });
      } else {
        this.$emit("change", null);
      }
    },
    decimalBegin() {
      if (!!this.decimalBegin || !!this.decimalEnd) {
        let begin = this.decimalBegin === undefined ? null : this.decimalBegin;
        let end = this.decimalEnd === undefined ? null : this.decimalEnd;
        this.$emit("change", { begin, end });
      } else {
        this.$emit("change", null);
      }
    },
    decimalEnd() {
      if (!!this.decimalBegin || !!this.decimalEnd) {
        let begin = this.decimalBegin === undefined ? null : this.decimalBegin;
        let end = this.decimalEnd === undefined ? null : this.decimalEnd;
        this.$emit("change", { begin, end });
      } else {
        this.$emit("change", null);
      }
    },
    basicValue() {
      this.$emit("change", this.basicValue);
    },
  },
  computed: {
    options() {
      let options = null;
      let userRole = this.$store.state.AppActiveUser.role;

      if (this.field.type === "alias" || this.field.type === "aliasArray") {
        let collectionName = this.field.collection;

        if (
          ["users", "customers", "establishments", "lawyers"].indexOf(
            collectionName
          ) >= 0
        ) {
          options = this.collections[collectionName];
        } else if (collectionName === "courts") {
          options = [];
        }
      } else if (this.field.type === "select") {
        options = this.field.options;
      } else if (
        this.field.type === "enum" ||
        this.field.type === "enumArray"
      ) {
        const enumType = this.field.enumType;
        if (enumType === "caseStatuses") {
          let customerId = ["customer", "supercustomer"].includes(userRole)
            ? this.$store.state.AppActiveUser.customer.id
            : null;
          options = this.collections.caseStatuses
            .filter(
              (x) =>
                !x.customers || !customerId || x.customers.includes(customerId)
            )
            .sort((a, b) => (a.order > b.order ? 1 : -1));
        } else {
          options = this.field.options || this.collections[enumType];
        }
      }

      return options;
    },
  },
};
</script>
<style lang="scss">
.field-loading {
  position: relative;
  width: 100%;
  height: 50px;

  .loading {
    top: calc(50% - 15px);
    width: 40px;
    height: 40px;
  }
}

.vdatetime-input {
  width: 100%;
}
</style>
