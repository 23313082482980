<template>
  <div v-if="isReady">
    <vs-sidebar
      click-not-close
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="penal-reatail-sidebar items-no-padding"
      spacer
      v-model="showSidebar"
    >
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ $t("pages.cases.advancedSearch") }}</h4>
        <div>
          <feather-icon
            icon="SaveIcon"
            @click.stop="applyFilters"
            class="cursor-pointer pr-3"
            v-if="hasFiltersApplied"
          ></feather-icon>
          <feather-icon
            icon="TrashIcon"
            @click.stop="removeAdvancedSearch"
            class="cursor-pointer pr-3"
            v-if="hasFiltersApplied"
          ></feather-icon>
          <feather-icon
            icon="XIcon"
            @click.stop="
              showSidebar = false;
              $router.push('/');
            "
            class="cursor-pointer pr-3"
            v-if="!hasFiltersApplied"
          ></feather-icon>
        </div>
      </div>
      <vs-divider class="mb-0"></vs-divider>
      <div class="p-0">
        <vs-tabs alignment="fixed">
          <vs-tab
            :label="$t(`advancedSearch.tabs.${a.key}`)"
            v-for="(a, ai) in advancedSearchAreas"
            v-bind:key="`tab_${ai}`"
            :tagColor="a.isFiltered ? `danger` : `primary`"
          >
            <VuePerfectScrollbar
              class="scroll-area--data-list-add-new"
              :settings="{ maxScrollbarLength: 60, wheelSpeed: 0.6 }"
              :key="$vs.rtl"
            >
              <div
                class="vx-col w-full mb-2 sm:w-1/1 py-3 px-6"
                :class="{
                  'background-selected': f.isFiltered,
                }"
                v-for="(f, fi) in a.attributes"
                v-bind:key="`field_${fi}`"
              >
                <label
                  class="vs-input--label"
                  :class="{
                    'text-danger': f.isFiltered,
                  }"
                  >{{ $t(`advancedSearch.fields.${f.key}`) }}</label
                >
                <br />
                <SearchSimpleCriteria
                  :field="f"
                  :model="savedAdvancedSearch"
                  :collections="searchCollections"
                  @change="(v) => updateAdvancedSearchModel(f, v)"
                />
              </div>
            </VuePerfectScrollbar>
          </vs-tab>
        </vs-tabs>
      </div>
    </vs-sidebar>
    <template v-if="!processedCases">
      <vx-card>
        <div class="mt-6 mb-8 text-center" style="height: 330px">
          <feather-icon
            icon="AlertOctagonIcon"
            svgClasses="h-24 w-24"
            class="mt-20 mr-2"
          />
          <h2>{{ $t("advancedSearch.labels.noCriteriaDefined") }}</h2>
        </div>
      </vx-card>
    </template>
    <template v-else>
      <ListPage
        :gridData="processedCases"
        :gridConfig="gridConfig"
        :filters="filters"
      />
    </template>
  </div>

  <div class="custom-absolute-loading" v-else>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ListPage from "@/components/penal-retail-helpers/ListPage";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

import SearchSimpleCriteria from "@/components/penal-retail-helpers/SearchSimpleCriteria";

import searchHelpers from "@/helpers/advancedSearchHelper";

const NEW_CASE_STATUS_ID = "mjkcEWDfWPAdPsaXmRB1"; //ID de estado de caso "nuevo"
import caseStatuses from "@/data/cases/lists/caseStatuses.json";

import penalTypes from "@/data/cases/lists/penalTypes";
import trialStatuses from "@/data/cases/lists/trialStatuses";
import complaintPlaces from "@/data/cases/lists/complaintPlaces";
import complaintHolders from "@/data/cases/lists/complaintHolders";

export default {
  components: {
    SearchSimpleCriteria,
    ListPage,
    VuePerfectScrollbar,
  },
  data() {
    let columns = [];
    const collectionName = "cases";

    columns.push({ field: "cid", filter: false, width: 50 });
    columns.push({
      field: "datetime",
      filter: false,
      width: 75,
      valueFormatter: (data) => {
        return (
          this.$moment(data.value.toDate()).format("DD-MM-YYYY HH:mm") || null
        );
      },
    });

    if (
      !["customer", "supercustomer"].includes(
        this.$store.state.AppActiveUser.role
      )
    ) {
      columns.push({
        filter: false,
        width: 75,
        field: "customer",
        valueGetter: (params) => {
          return params.data.customer.alias;
        },
        filterValueGetter: (params) => {
          return params.data.customer.id;
        },
      });
    }
    columns.push({
      field: "establishment",
      filter: false,
      width: 100,
      valueGetter: (params) => {
        return params.data.establishment.alias;
      },
      filterValueGetter: (params) => {
        return params.data.establishment.id;
      },
    });

    columns.push({
      field: "lawyer",
      filter: false,
      width: 100,
      valueGetter: (params) => {
        return params.data.lawyer ? params.data.lawyer.alias : null;
      },
      filterValueGetter: (params) => {
        return params.data.lawyer ? params.data.lawyer.id : null;
      },
    });
    columns.push({
      field: "penalType",
      filter: false,
      width: 100,
      valueGetter: (params) => {
        if (params.data.penalType) {
          let myTypes = params.data.penalType;
          if (!Array.isArray(myTypes)) {
            myTypes = [myTypes];
          }
          return myTypes
            .map((p) => penalTypes.find((x) => x.id === p.id))
            .map((p) => this.$t(`enums.penalTypes.${p.key}`))
            .join("+");
        } else {
          return null;
        }
      },
      filterValueGetter: (params) => {
        if (params.data.penalType) {
          let myTypes = params.data.penalType;
          if (!Array.isArray(myTypes)) {
            myTypes = [myTypes];
          }
          return myTypes.map((p) => p.id);
        } else {
          return null;
        }
      },
    });
    columns.push({
      field: "trialDatetime",
      filter: false,
      width: 75,
      valueFormatter: (data) => {
        return data.value
          ? this.$moment(data.value.toDate()).format("DD-MM-YYYY HH:mm")
          : null;
      },
    });
    columns.push({
      field: "caseStatus",
      filter: false,
      width: 50,
      filterValueGetter: (params) => {
        return params.data.caseStatus ? params.data.caseStatus.id : null;
      },
      cellRendererFramework: "CellRendererStatus",
    });

    columns = columns.map((c) => ({
      ...c,
      headerName:
        c.field === "caseStatus"
          ? ""
          : this.$t(`${collectionName}.columns.${c.field}`),
    }));
    return {
      savedAdvancedSearch: {},
      processedCases: null,
      gridConfig: {
        components: {},
        gridOptions: {},
        columnDefs: columns,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onRowClicked: this.onRowClicked,
      },
      showSidebar: true,
      refreshSidebar: false,
      showProcessedCases: false,

      users: null,
    };
  },
  computed: {
    ...mapGetters([
      "allCases",
      "allCustomers",
      "allEstablishments",
      "allLawyers",
    ]),
    searchCollections() {
      //Para el caso de establecimientos, sólo debemos mostrar una opción de empresa, por eso quitamos todas las que tenemos y definimos una única versión
      const establishments = this.allEstablishments.some(
        (x) => x.isForAllCustomer
      )
        ? [].concat(
            [
              {
                id: "empresa",
                alias: this.$t("establishments.labels.wholeCompany"),
                isForAllCustomer: true,
              },
            ],
            this.allEstablishments.filter((x) => !x.isForAllCustomer)
          )
        : this.allEstablishments;

      return {
        penalTypes,
        caseStatuses,
        trialStatuses,
        complaintPlaces,
        complaintHolders,

        customers: this.allCustomers,
        establishments,
        lawyers: this.allLawyers,
      };
    },
    isReady() {
      return (
        !this.refreshSidebar &&
        !Object.values(this.searchCollections).some((x) => !x)
      );
    },
    isLawyer() {
      return this.$store.state.AppActiveUser.role === "lawyer";
    },
    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },
    hasFiltersApplied() {
      return Object.keys(this.savedAdvancedSearch).length > 0;
    },
    advancedSearchAreas() {
      let filters = this.savedAdvancedSearch;
      let areas = searchHelpers.attributes(
        this.$store.state.AppActiveUser.role
      );
      areas.forEach((a) => {
        a.attributes.forEach((f) => {
          f.isFiltered = filters[f.key] != null && filters[f.key] != undefined;
          a.isFiltered = a.isFiltered || f.isFiltered;
        });
      });
      return areas;
    },
    filters() {
      let filters = [];
      filters.push({ size: "2/3", mobileSize: "1/2", search: true });
      filters.push({
        size: "1/3",
        mobileSize: "1/2",
        onClickButton: true,
        onClick: this.toggleSidebar,
        i18n: `pages.cases.advancedSearch`,
        extraClasses: "text-right",
        color: this.hasFiltersApplied ? "danger" : null,
      });
      return filters;
    },
  },
  methods: {
    recalculateCases() {
      if (this.hasFiltersApplied) {
        let findLawyer = (lawyerId) => {
          let lawyer = this.allLawyers.find((l) => l.id === lawyerId);
          return lawyer ? { id: lawyer.id, alias: lawyer.alias } : null;
        };

        let findCustomer = (customerId) => {
          return this.allCustomers.find((c) => c.id === customerId);
        };

        let findEstablishment = (establishmentId) => {
          return this.allEstablishments.find((c) => c.id === establishmentId);
        };

        let processedCases = []
          .concat(
            this.allCases.filter((c) => !!findEstablishment(c.establishment.id))
          )
          .map((x) => ({ id: x.id, ...x }));
        for (let c of processedCases) {
          if (!c.lawyer) {
            let customer = c.customer ? findCustomer(c.customer.id) : null;
            let establishment = c.establishment
              ? findEstablishment(c.establishment.id)
              : null;
            let lawyerId =
              establishment && establishment.lawyer
                ? establishment.lawyer.id
                : customer && customer.lawyer
                ? customer.lawyer.id
                : null;
            if (lawyerId) {
              c.lawyer = findLawyer(lawyerId);
            }
          }

          if (!c.caseStatus) {
            let newStatus = caseStatuses.find(
              (s) => s.id === NEW_CASE_STATUS_ID
            );
            c.caseStatus = newStatus;
          }
        }

        //---- APLICAMOS LOS FILTROS ----
        if (Object.keys(this.savedAdvancedSearch)) {
          processedCases = processedCases.filter((c) =>
            searchHelpers.filterSimpleCriteria(
              this.$store.state.AppActiveUser.role,
              c,
              this.savedAdvancedSearch
            )
          );
        }
        //-------------------------------

        this.processedCases = processedCases;
      }
    },
    onRowClicked(params) {
      window.open(
        this.$router.resolve({
          name: "main-cases-edit",
          params: { id: params.data.id },
        }).href
      );
    },
    isFilteredArea(area) {
      let result = false;
      for (let i = 0; i < area.attributes.length && !result; i++) {
        result = this.isFilteredField(area.attributes[i]);
      }

      return result;
    },
    isFilteredField(field) {
      return (
        this.savedAdvancedSearch[field.key] != null &&
        this.savedAdvancedSearch[field.key] != undefined
      );
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    updateAdvancedSearchModel(field, value) {
      let model = this.savedAdvancedSearch;
      if (
        value === null ||
        value === undefined ||
        (Array.isArray(value) && value.length === 0)
      ) {
        delete model[field.key];
      } else {
        model[field.key] = value;
      }
      this.savedAdvancedSearch = { ...model };
    },
    removeAdvancedSearch() {
      this.savedAdvancedSearch = {};
      this.processedCases = null;
      this.showSidebar = false;
      setTimeout(() => {
        this.refreshSidebar = true;
        setTimeout(() => {
          this.refreshSidebar = false;
          this.showSidebar = true;
        }, 100);
      });
    },
    applyFilters() {
      this.recalculateCases();
      this.showSidebar = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.background-selected {
  background-color: rgba(var(--vs-danger), 0.1) !important;
}

.penal-reatail-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 45px);
}
</style>

<style lang="scss">
.penal-reatail-sidebar {
  [tagcolor="danger"] {
    color: rgba(var(--vs-danger), 0.5);
  }

  .activeChild button[tagcolor="danger"],
  button:not(:disabled)[tagcolor="danger"]:hover {
    color: rgba(var(--vs-danger), 1) !important;
    background-color: rgba(var(--vs-danger), 0.1) !important;
  }

  .activeChild button:not([tagcolor="danger"]) {
    background-color: rgba(var(--vs-primary), 0.1) !important;
  }

  .line-vs-tabs {
    display: none;
  }

  .vs-tabs--content {
    padding: 0;
  }
}
</style>
